.addDropdown {
    position: relative;
    display: inline-block;
}

.addButton {
    border: 0px;
    border-radius: 10px;
    background-color: #f7f9f9;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.addButton:hover {
    background-color: rgb(236, 236, 236);
}

.addButton span {
    position: relative;
    font-weight: lighter;
    font-size: 27px;
    top: 50%;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.addContainer {
    width: 450px;
    height: 360px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px gray;
    position: absolute;
}

.addContainer .inputData {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.addContainer .inputData textarea {
    resize: none;
    border: none;
    background: rgba(128,128,128,0.1);
    width: 366px;
    padding-left: 20px;
    border-radius: 3px;
}

.addContainer .inputData input {
    padding-left: 20px;
    border: none;
    height: 30px;
    background: rgba(128,128,128,0.1);
}

.addContainer .inputData input[type=text] {
    transition: all 0.30s ease-in-out;
    /*border: 1px solid rgba(128,128,128,0.1);*/
    border-radius: 3px;
    background: rgba(128,128,128,0.1);
}

.addContainer .inputData input[type=text]:focus {
    border-bottom: 1px solid blue;
}