/******************/
/* root container */
/******************/
.conferenceSettings-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.conferenceSettings-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    opacity: 0.4;
    background: url("../../assets/v4h/background_quickConference02.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
/******************/
/* root container */
/******************/


/*********************/
/* settings container*/
/*********************/
.conferenceSettings-container .conferenceSettings-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 10px;
    margin-bottom: 40px;
    z-index: 1;
    box-shadow: 0px 0px 50px 10px black;
}
/*********************/
/* settings container*/
/*********************/