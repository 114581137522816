.RecordedSessionsContainer {
  background: #f4f4f4 !important;
  border-top: 1px solid #DFDFDF;
  border-right: 1px solid #DFDFDF;
  border-left: 1px solid #DFDFDF;
  border-radius: 3px;
  margin-top: 12px;
}

.RecordedSessionsContainer .ant-table-wrapper .ant-spin-nested-loading {
    z-index: 0;

}

.RecordedSessionsContainer .ant-spin-nested-loading > div > ul > li {
  border: none;
  padding: 20px 16px;
}

.RecordedSessionsContainer .ant-list-items {
  border-bottom: 1px solid #DFDFDF;
}

.RecordedSessionsContainer .ant-list-item {
  border-bottom: 1px solid #DFDFDF;
}

.RecordedSessionsContainer .ant-list-item:hover {
  background-color: #f7f7f7;
}

.RecordedSessionsContainer .listItem {
  display: flex;
  cursor: pointer;
  height: 64px;
  width: 100%;  
}

.RecordedSessionsContainer .listItem .content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px 0px;
}

.RecordedSessionsContainer .listItem .date {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 50px;
  /* padding-top: 15px; */
  margin-left: 4px;
  margin-right: 10px;
}

.RecordedSessionsContainer .listItem .lineContent {
  height: 100%;
  width: 25px;
  /* padding: 15px 9px; */
}

.RecordedSessionsContainer .listItem .line {
  background-color: mediumvioletred;
  height: 90%;
  width: 9px;
  border-radius: 3px;
}

.RecordedSessionsContainer .listItem .day {
  font-weight: bold;
  font-size: 25px;
  font-family: 'Open Sans', monospace;
  color: #424949;
  line-height: 1;
}

.RecordedSessionsContainer .listItem .month {
  font-size: 12px;
  color: #424949;
}

.RecordedSessionsContainer .listItem .year {
  font-size: 10px
}

.RecordedSessionsContainer .listItem .infoContent {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* padding: 12px 0px 0px 10px; */
  font-family: 'Open Sans', monospace;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RecordedSessionsContainer .infoContent:hover {
  transform: translateX(1px) !important;
  transition: transform 0.2s ease-in !important;
}

.RecordedSessionsContainer .listItem .infoContent .title {
  font-weight: bold;
  color: #494949;
  color: rgb(59,59,59);
  font-size: 20px;
  line-height: 1;
  margin: 0px;
  margin-bottom: 3px;
}

.RecordedSessionsContainer .listItem .infoContent .description {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 42px;
  line-height: 1.2;
  color: #606060;
  margin-bottom: 2px;
}

.RecordedSessionsContainer .listItem .time {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50px;
    padding-left: 10px;
}

.RecordedSessionsContainer .listItem .title-host {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 70%;
    padding-left: 15px;
}

.RecordedSessionsContainer .listItem .title-host h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-height: 20px;
    margin: 0px;
}

.RecordedSessionsContainer .listItem .title-host .host {
    text-align: left;
    font-size: 12px;
}

.RecordedSessionsContainer .ActionsButton {
  display: flex;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  /* margin: 42.5px 5px 42.5px 20px; */
}

#container > div > div.ant-list.ant-list-split.ant-list-something-after-last-item > div.ant-list-pagination > ul > li.ant-pagination-item {
  display: none;
}

#root .RecordedSessionsContainer > div > div.ant-list-pagination > ul > li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
    display: none;
}

#root .RecordedSessionsContainer > div > div.ant-list-pagination > ul > li.ant-pagination-simple-pager {
    display: none;
}

.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid red;
}

#root .RecordedSessionsContainer > div > div.ant-spin-nested-loading > div > ul > li {
  border-bottom: 1px solid #DFDFDF;

}

#root .RecordedSessionsContainer > div > div.ant-spin-nested-loading > div > ul > li {
  border-bottom: 1px solid #DFDFDF;
}

#root .RecordedSessionsContainer > div > div.ant-spin-nested-loading > div > ul > li:last-child {
  border-bottom: none;
}

#root > section > div.sessionBody > div.ant-row.sessions > div > div.RecordedSessionsContainer > div > div.ant-list-pagination {
  position: absolute;
  margin: 0px;
  top: -53px;
}