.login-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--purple);
  flex-direction: column;
}

.login-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.2;
  background-image: url("../../assets/v4h/teleconferencia.jpg");
}

.login-container form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.login-container .container-img {
  display: flex;
  justify-content: center;
}

.login-container img {
  max-width: 100px;
}

.login-container form input {
  margin-top: 10px;
  height: 48px;
  background: none;
  border: 2px solid var(--withe);
  border-radius: 4px;
  padding: 0 20px;
  font-size: 16px;
  color: var(--withe);
}

.login-container form input::placeholder {
  color: var(--withe);
}

.login-container form input:focus {
  color: var(--withe);
  border: 2px solid var(--turquoise);
  -webkit-transition-property: border;
  -webkit-transition-duration: 1s;
}

.login-container form .forgetPassword {
  color: var(--withe);
  padding-top: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 5px;
}

.login-container form .submit {
  margin-top: 10px;
  border: 0;
  border-radius: 4px;
  height: 48px;
  font-size: 16px;
  background-color: var(--turquoise);
  font-weight: bold;
  color: var(--withe);
  cursor: pointer;
  transition: background-color 0.5s;
}

.login-container form .submit:hover {
  background-color: var(--dark-turquoise);
}

.registerButtonSvg,
.registerButtonRect {
  max-width: 200px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.registerButtonContainer {
  position: relative;
  top: 60px;
  margin-top: 0 auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.registerButtonRect {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -272;
  stroke-width: 5px;
  fill: transparent;
  stroke: var(--turquoise);
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

.registerButtonText {
  font-family: "Roboto Condensed";
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 3px;
  color: var(--withe);
  position: absolute;
  top: 3px;
  cursor: pointer;
}

.registerButtonContainer:hover .registerButtonRect {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}
