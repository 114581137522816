.container-NewQuickConference {
  width: 100vw;
  height: 100vh;
  border: 1px solid red;
  background-image: url('../../assets/v4h/background01.jpg');
  background-size: cover;
}

.container-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
}

.input-container {
  position: relative;
  width: 500px;
  height: 200px;
  display: flex;
  background-color: hsla(263, 100%, 34%, 0.884);
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.confname-input {
  width: 350px;
  height: 50px;
  margin-right: 15px;
  border-radius: 10px;
  padding-left: 10px;
}

.submit-button {
  height: 50px;
  width: 100px;
  border: none;
  border-radius: 10px;
  background-color: var(--dark-turquoise);
  color: white;
}

.inputError {
  border: 2px solid red !important;
}

