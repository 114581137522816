.ActionsButton {
  display: flex;
}

.ActionsButton .playButton button {
  box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px !important;
  border-radius: 2px;
  align-content: flex-start;
  background-color: mediumvioletred;
  border: 1px solid mediumvioletred;
  margin-right: 5px;
  height: 35px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 15px;
  font-family: "Open Sans", monospace;
}

.ActionsButton .playButton button i {
  font-size: 18px;
  margin-right: 5px;
}

.ActionsButton .playButton button:hover {
  transform: translateY(-5%);
  transition: all 0.4s;
}

.ActionsButton a {
  color: #5f5f5f;
}

.ActionsButton a:hover {
  color: rgb(2, 91, 255);
}

.disable {
  background-color: rgb(158, 158, 158) !important;
  border: 1px solid rgb(158, 158, 158) !important;
}

.disabled {
  display: none;
}
