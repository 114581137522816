/******************/
/* root container */
/******************/
.conferenceSettings-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/******************/
/* root container */
/******************/

.nameConference {
    font-size: 38px;
    color: white;
    padding-top: 10px;
}

/***********************************/
/* Settings for buttons containers */
/***********************************/
.conferenceSettings-body .buttons .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 2px solid white; 
    border-radius: 100%;
    margin: 0px 20px;
}

.conferenceSettings-body .buttons .btn-div:hover {
    border: 2px solid gray;
    background-color: rgba(0, 0, 0, 0.712);
    -webkit-transition-property: background-color, border;
    -webkit-transition-duration: 0.5s;
}
/***********************************/
/* Settings for buttons containers */
/***********************************/


/*****************************/
/* Settings for camera space */
/*****************************/
.conferenceSettings-body .conferenceSettings-cam {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 301px;
    background-color: black;
    border: 2px solid white;
    margin: 20px 0px;
}

.conferenceSettings-body .conferenceSettings-cam #webCamera {
    width: 100%;
    height: 100%;   
}

.conferenceSettings-body .conferenceSettings-cam #no-webCamera {
    color: white;
    font-size: 30px;
}
/*****************************/
/* Settings for camera space */
/*****************************/


/************************/
/* Settings for buttons */
/************************/
.conferenceSettings-body .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px;
}

.conferenceSettings-body .btn {
    width: 70%;
    height: 70%;
    color: white;
}

.conferenceSettings-body .btn_startConf {
    border: none;
    background-color: var(--buttonColor);
    color: var(--buttonFontColor);
    border-radius: 5px;
    padding: 10px 50px;
    margin: 0px 0px 30px 0px;
    font-size: var(--buttonFontSize);
}

.conferenceSettings-body .btn_startConf:hover {
    background-color: var(--buttonColorHover);
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 0.5s;
}
/************************/
/* Settings for buttons */
/************************/

/***********/
/* outhers */
/***********/
.conferenceSettings-body .participants {
    color: white;
    margin-top: 10px;
    visibility: hidden;
}